export default [
  {
    isbn: "180810285-1",
    title: "Freeze Me",
    authors: ["Dennet Tomaskunas", "Zandra Drewet"],
    pinned: false,
    active: false,
  },
  {
    isbn: "979236693-8",
    title:
      "Disney Princess Collection: Jasmine's Enchanted Tales: Jasmine's Wish",
    authors: ["Lissa Drinkel"],
    pinned: false,
    active: false,
  },
  {
    isbn: "764731358-4",
    title: "Immortals",
    authors: ["Shell Patifield"],
    pinned: false,
    active: false,
  },
  {
    isbn: "516134140-8",
    title: "Reel Rock 7 ",
    authors: [
      "Almire Mustarde",
      "Trevor Svanini",
      "Stefanie Collum",
      "Dal Calles",
      "Bernie Dwight",
    ],
    pinned: false,
    active: false,
  },
  {
    isbn: "054421714-4",
    title: "Men in White",
    authors: [
      "Garvey Shouler",
      "Elspeth Braddick",
      "Pat Bernardeau",
      "Kirbie Toller",
    ],
    pinned: false,
    active: false,
  },
  {
    isbn: "423486862-0",
    title: "Battle Creek Brawl (Big Brawl, The)",
    authors: ["Marguerite MacHoste", "Fernanda Kynge", "Mathe Lomasney"],
    pinned: false,
    active: true,
  },
  {
    isbn: "512110249-6",
    title: "Kosmos",
    authors: [
      "Mariel Utton",
      "Izabel Jenicke",
      "Katalin Duval",
      "Alasdair Ofield",
      "Pancho Crummay",
    ],
    pinned: false,
    active: false,
  },
  {
    isbn: "484821195-6",
    title: "At Middleton",
    authors: ["Lotte Oswald", "Amalea Caudwell", "Haley Dumbreck"],
    pinned: true,
    active: false,
  },
  {
    isbn: "305998466-2",
    title: "Whistle Blower, The",
    authors: ["Stoddard Janosevic", "La verne Riglesford", "Myrta Liverseege"],
    pinned: true,
    active: false,
  },
  {
    isbn: "532544570-7",
    title: "Estomago: A Gastronomic Story",
    authors: ["Lillis Lamball"],
    pinned: true,
    active: false,
  },
  {
    isbn: "678263058-3",
    title:
      "Nobody Will Speak of Us When We're Dead (Nadie hablará de nosotras cuando hayamos muerto)",
    authors: [
      "Valentina de Cullip",
      "Hamilton Farres",
      "Lauren Essam",
      "Garvin Sainthill",
    ],
    pinned: false,
    active: true,
  },
  {
    isbn: "263210481-5",
    title: "Stranger, The (Agantuk) (Visitor, The)",
    authors: ["Horton Barratt", "Niki Pugsley", "Deni Treadgear"],
    pinned: true,
    active: false,
  },
];
